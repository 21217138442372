import React from "react";

const TermsAndConditionsPage = () => {
  return (
    <div className="relative bg-gray-100 min-h-screen flex flex-col items-center py-10 mt-20 overflow-hidden">
      {/* Background SVG Blobs */}
      <div className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-[600px] h-[600px]">
        <svg
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full"
        >
          <path
            fill="#FBD7E3"
            d="M44.4,-65.5C55.4,-57.7,59.9,-41.5,65.6,-25.9C71.3,-10.3,78.1,5,76.5,19.6C75,34.2,65.2,47.9,53.1,58.1C41,68.3,26.6,74.8,12.3,73.8C-2,72.8,-16.4,64.4,-31.2,57.4C-46,50.5,-61.2,45.1,-66.4,36.3C-71.6,27.6,-66.8,15.5,-66.7,3.1C-66.7,-9.3,-71.4,-21.9,-66.5,-30.4C-61.6,-39,-47.1,-43.5,-34.3,-51.7C-21.5,-59.9,-10.8,-71.8,3.1,-76.4C17.1,-81,34.1,-78.1,44.4,-65.5Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>

      <div className="absolute bottom-0 right-0 transform translate-x-1/2 translate-y-1/2 w-[500px] h-[500px]">
        <svg
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full"
        >
          <path
            fill="#FBD7E3"
            d="M40.4,-64.2C54.4,-57.8,65.7,-45.8,71.7,-31.4C77.8,-17,78.6,-0.2,75.4,15.3C72.1,30.8,64.9,45,54.1,53.5C43.2,62.1,28.6,64.9,13.5,65.5C-1.7,66.1,-17.2,64.4,-33.3,58.6C-49.4,52.8,-66,42.9,-73.7,28.9C-81.3,14.9,-80.1,-3.2,-73.3,-17.7C-66.6,-32.2,-54.3,-42.9,-41.4,-49.8C-28.5,-56.8,-14.2,-59.9,0.8,-60.8C15.8,-61.8,31.5,-60.6,40.4,-64.2Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>

      {/* Terms and Conditions Content */}
      <div className="max-w-5xl text-left bg-white shadow-xl rounded-2xl p-10 relative z-10 border border-gray-300">
        <h1 className="text-4xl font-bold text-pink-700 mb-8 text-center">Terms and Conditions</h1>

        <div className="space-y-8 text-gray-700 leading-relaxed">
          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Introduction</h2>
            <p className="font-medium">
              These Terms and Conditions govern the use of the platform operated by
              <span className="font-bold"> Maheshwari Innovative IT Services LLP</span>, a company incorporated under the
              Companies Act, 1956, with its registered office at <span className="font-bold">CO SUSHEELA DEVI, TULSI
                RESIDENCY, Goverdhan, Govardhan SIYARAM BABA ASHRAM, Mathura, India</span>
              {/* (hereinafter referred to as <span className="font-bold">"Platform Owner", "we", "us", "our"</span>). */}
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Agreement</h2>
            <p>
              By accessing, browsing, or otherwise using the platform, you <span className="font-bold">agree to comply
                with these Terms and Conditions</span>. These terms constitute your binding obligations with the Platform Owner.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">User Obligations</h2>
            <ul className="list-disc ml-6 space-y-2">
              <li><span className="font-bold">Provide true, accurate, and complete information</span> during registration.</li>
              <li>Ensure the security of your account.</li>
              <li>
                Acknowledge that your use of the platform and services is entirely at your <span className="font-bold">own risk</span>.
              </li>
              <li>
                Refrain from claiming intellectual property rights over the platform's
                contents, including design, layout, and graphics.
              </li>
              <li>
                Avoid using the platform for <span className="font-bold">unlawful or prohibited activities</span> as per these
                terms or applicable laws.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Warranties and Liabilities</h2>
            <p>
              We do not provide any <span className="font-bold">warranties or guarantees</span> regarding the accuracy,
              completeness, or suitability of the information or services provided on the
              platform. Your use of the platform is at your <span className="font-bold">sole discretion and risk</span>. We
              exclude liability for any inaccuracies or errors to the fullest extent
              permitted by law.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Indemnification</h2>
            <p>
              You agree to <span className="font-bold">indemnify and hold harmless</span> the Platform Owner, its affiliates,
              officers, directors, and employees from any claims or demands arising out of
              your breach of these Terms, violation of any laws, or infringement of
              third-party rights.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Force Majeure</h2>
            <p>
              The Platform Owner shall not be held liable for failure to perform its
              obligations under these terms due to events beyond its reasonable control,
              including but not limited to <span className="font-bold">acts of God, natural disasters, or legal
                restrictions</span>.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Governing Law and Jurisdiction</h2>
            <p>
              These terms shall be governed by and construed in accordance with the <span className="font-bold">laws of
                India</span>. All disputes arising out of or related to these terms shall be
              subject to the <span className="font-bold">exclusive jurisdiction of the courts in Mathura, Uttar
                Pradesh</span>.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Amendments</h2>
            <p>
              The Platform Owner reserves the right to <span className="font-bold">amend or modify these terms</span> at any
              time without prior notice. It is your responsibility to review these terms
              periodically.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Refund Policy</h2>
            <p>
              All fees paid to the Platform are <span className="font-bold">non-refundable</span>. Once a payment is made,
              no refunds will be provided under any circumstances. By making a payment,
              you acknowledge and agree to this <span className="font-bold">no-refund policy</span>.
            </p>
          </section>


          <section>
            <h2 className="text-2xl font-semibold text-pink-700 mb-3">Contact Information</h2>
            <p>
              For any concerns or communications related to these Terms, please contact us
              using the information provided on the website.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
