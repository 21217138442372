import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

// Example response JSON (this should ideally come from an API call)
const responseData = {
    "amount": 0.0,
    "benefits": ["Benefits"],
    "bestSeller": 0,  // 1 = Best Seller, 0 = Regular Plan
    "mobileNumber": "0000000000",
    "name": "Username",
    "planDescription": "Access to premium features",
    "planDuration": 0,  // In days
    "planDurationMsg": "Duration",
    "planId": 0,
    "planName": "Plan Name",
    "userId": "User Id"
};

export default function Checkout() {
    const [selectedPlan, setSelectedPlan] = useState(responseData);
    const [coupon, setCoupon] = useState("");
    const [discount, setDiscount] = useState(0);
    const [isLoading, setIsLoading] = useState(true); // Track loading state
    const [error, setError] = useState<string | null>(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token"); // Get token from URL
    const domain = "https://api.smartmaheshwari.com/"

    useEffect(() => {
        const url = domain +"checkout?token=" + token
        axios.get(url).then((res) => {
            const response = res.data
            console.log(response)
            setSelectedPlan(response ?? responseData);
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setSelectedPlan(responseData);
            setIsLoading(false);
            setError("There is something wrong with your token please try again.")
        });
        },[false])

    // Function to format the benefits properly
    const parseBenefits = (benefitsArray: string[]) => {
        return benefitsArray.map((benefit) => benefit.replace(/["\n]/g, "").trim());
    };

    const applyCoupon = () => {
        if (coupon === "SAVE10") {
            setDiscount(10);
        } else {
            setDiscount(0);
            alert("Invalid coupon code");
        }
    };

    const handleProceedPayment = () => {
        const url = domain +"create-phonepe-order"
        const payload = {
            userId: selectedPlan.userId,
            planId: selectedPlan.planId,
            amount: selectedPlan.amount,
            coupon: coupon,
            token: token
        }
        axios.post(url, payload).then((res) => {
            const response = res.data
            console.log(response)
            if (response.status.toLowerCase() == "success") {
                window.location.href = response.url
            } else {
                setError(response.message)
            }
        }).catch((err) => {
            console.log(err);
            setError("There is something wrong with your token please try again.")
        });

    }

    const closeModal = () => {
        setError(null);
    };

    if (isLoading) {
        return (
            <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                <div className="loader border-t-4 border-blue-500 border-solid rounded-full w-12 h-12 animate-spin"></div>
            </div>
        );
    }

    const discountedPrice = selectedPlan.amount - discount;
    const totalAmount = discountedPrice;
    const button_disable = Number(selectedPlan.amount) == 0

    return (
        <div className="max-w-lg mx-auto p-6 bg-white shadow-lg rounded-lg mt-20 mb-10">
            <h2 className="text-2xl font-semibold text-center mb-4">Checkout</h2>

            <div className={`border rounded-lg p-4 ${selectedPlan.bestSeller ? "border-yellow-500 bg-yellow-100" : ""}`}>
                <h3 className="text-xl font-semibold">{selectedPlan.planName}</h3>
                <p className="text-gray-600">₹{selectedPlan.amount} {selectedPlan.planDurationMsg}</p>
                <p className="text-gray-500">{selectedPlan.planDescription}</p>

                <div className="mt-2">
                    {parseBenefits(selectedPlan.benefits).map((benefit, index) => (
                        <p key={index} className="text-gray-700">✔ {benefit}</p>
                    ))}
                </div>

                {selectedPlan.bestSeller ? (
                    <p className="text-yellow-700 font-semibold mt-2">🔥 Best Seller Plan</p>
                ) : null}
            </div>

            <div className="mt-4 border p-4 rounded-lg bg-gray-100">
                <label className="block text-gray-700 font-medium">Coupon Code:</label>
                <input
                    type="text"
                    className="w-full p-2 border rounded-md mt-1"
                    placeholder="Enter coupon code"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                />
                <button
                    className="w-full bg-blue-500 text-white py-2 px-4 mt-2 rounded-lg hover:bg-blue-600"
                    onClick={applyCoupon}
                >
                    Apply Coupon
                </button>
            </div>

            <div className="mt-4 border-t pt-4">
                <p className="text-gray-700">Subtotal: ₹{discountedPrice.toFixed(2)}</p>
                {discount > 0 && <p className="text-green-600">Discount Applied: -₹{discount.toFixed(2)}</p>}
                <p className="text-xl font-semibold mt-2">Total Amount: ₹{totalAmount.toFixed(2)}</p>
            </div>

            <button className={`w-full bg-pink-600 text-white py-2 px-4 mt-4 rounded-lg hover:bg-pink-700 disabled:bg-gray-400 disabled:cursor-not-allowed`} disabled={button_disable}
            onClick={handleProceedPayment}>
                Proceed to Payment
            </button>
            {/* 🔥 ERROR MODAL 🔥 */}
            {error && (
                <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm text-center">
                        <h3 className="text-lg font-semibold text-red-600">Error</h3>
                        <p className="text-gray-700 mt-2">{error}</p>
                        <button
                            className={`bg-red-500 text-white px-4 py-2 mt-4 rounded-lg hover:bg-red-600`}
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
